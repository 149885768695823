<!--会员中心首頁-->
<template>
    <div class="content">
        <MyBreads>會員中心</MyBreads>
        <el-row>
            <el-col :lg="3" :xs="0">
                &nbsp;
            </el-col>
            <el-col :lg="5" :md="8" :xs="0">
                <el-card style="margin: 10px;">
                    <h3>
                        <router-link to="/user-center" style="text-decoration: none;">
                            <i class="el-icon-user-solid"></i><span>會員中心</span>
                        </router-link>
                    </h3>

                    <el-menu
                            :router="true"
                            :default-active="currentPath"
                            @select="handleSelect"
                            class="el-menu-vertical-demo"
                            text-color="black"
                            active-text-color="blue">
                        <el-menu-item :index="item.url" v-for="(item, k) in sideMenu" :key="k">
                            <i :class="item.icon"></i>
                            <span>{{item.name}}</span>
                        </el-menu-item>
                    </el-menu>
                </el-card>
            </el-col>
            <el-col :lg="0" :xs:="24" class="hidden-sm-and-up">
                <div style="margin: 0px;">
                    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
                        <el-tab-pane v-for="(tab, ik) in sideMenu" :key="ik" :name="tab.url" >
                            <span slot="label"><i :class="tab.icon"></i> {{tab.name}}</span>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
            <el-col :lg="13" :md="6" :xs="24">
                <el-card style="margin: 10px;">
                    <el-row :gutter="20" class="user-center">
                        <router-view></router-view>

                    </el-row>
                </el-card>
            </el-col>
            <el-col :lg="3" :xs="0">
                &nbsp;
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import MyBreads from '@/components/MyBreads.vue';
    import {GetUser,GetUserLogout} from '@/request/api';
    import {mapState} from 'vuex';


    export default {
        name: 'UserCenter',
        components: {MyBreads},
        data() {
            return {
                activeName: '/user-center/orders',
                currentPath: '/user-center',
                menus: [
                    {index: 1, url: "/user-center/orders", icon: "el-icon-s-order", name: "我的訂單"},
                    {index: 2, url: "/user-center/address", icon: "el-icon-s-home", name: "我的地址"},
                    {index: 3, url: "/user-center/user", icon: "el-icon-s-custom", name: "帳戶資料"},
                    {index: 4, url: "/user-center/mdfpwd", icon: "el-icon-lock", name: "修改密碼"},
                    {index: 5, url: "/logout", icon: "el-icon-switch-button", name: "退出登入"}
                ],
            }
        },
        methods: {
            handleClick(tab) {
                if (tab.name == "/logout") {
                    let _this = this;
                    this.$confirm('確定要登出嗎？')
                        .then(() => {
                            _this.doLogout();
                        }).catch(() => {
                    });
                } else {
                    this.$router.push(tab.name);
                }
            },
            handleSelect(key, keyPath) {
                if (key == "/logout" && keyPath) {
                    /*this.$confirm('確定要登出嗎？')
                        .then(() => {
                            localStorage.removeItem("magor3-auth");
                            this.$router.push('/');
                        }).catch(() => {
                        this.$router.go(-1);
                    });*/
                    let _this = this;
                    this.$confirm('確定要登出嗎?？')
                        .then(() => {
                            _this.doLogout();
                        }).catch(() => {
                        this.$router.go(-1);
                    });
                }
            },
            doLogout(){
                GetUserLogout({}).then(res => {
                    if (res.code == 200) {
                        localStorage.removeItem("magor3-auth");
                        this.$store.commit('SET_LOGINED', false);
                        this.$router.push('/');
                    } else {
                        this.$toast.fail('網絡異常,請銷候再試!' + res);
                    }
                }).catch(e => {
                    console.log(e);
                    this.$toast.fail('網絡異常,請銷候再試!' + e);
                });
            },
            //鼠标滚动事件
            handleScroll(e) {
                //let name = this.activeName;
                let direction = e.deltaY > 0 ? 'down' : 'up';  //deltaY为正则滚轮向下，为负滚轮向上
                if (direction == 'down' && e.deltaY >= 100) {

                    let index = this.menus.find(item => item.url === this.activeName).index-1;
                    //let index = this.menus.indexOf(this.activeName);
                    if (index < this.menus.length-1) {
                        index++;
                        this.activeName = this.menus[index].url;
                    }
                }
                if (direction == 'up' && e.deltaY <= -100) {
                    let index = this.menus.find(item => item.url === this.activeName).index-1;
                    //let index = this.menus.indexOf(this.activeName);
                    if (index >= 1) {
                        index--;
                        this.activeName = this.menus[index].url;
                    }
                }
            },
            debounce(func, wait = 100, immediate = true) {
                let timer;
                return (...args) => {
                    let context = this;
                    if (timer) clearTimeout(timer);
                    if (immediate) {
                        let callNow = !timer;
                        timer = setTimeout(() => {
                            timer = null;
                        }, wait);
                        if (callNow) func.apply(context, args);
                    } else {
                        timer = setTimeout(() => {
                            func.apply(context, args);
                        }, wait);
                    }
                }
            },
        },
        created() {
            GetUser({}).then(res => {
                if (res.code == 200) {
                    this.$store.commit('SET_USER_REALNAME', res.data.realName);
                    this.$store.commit('SET_USER_NICKNAME', res.data.nickName);
                } else if (res.code == 401) {
                    this.$router.push('/login');
                }else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                console.log(err);
                this.$message.error('網路連線失敗，稍候請重試!');
            });
        }, watch: {
            $route(to, from) {
                if (from.path) {
                    this.currentPath = to.path;//到哪去
                }
            }
        },mounted() {
            //监听鼠标滚动事件
            //window.addEventListener('mousewheel', this.debounce(this.handleScroll),false);
        },
        computed:{
            ...mapState(['user']),
            sideMenu:{
                get(){
                    if (this.user.loginType!="email"){
                        let newMenu=[];
                        this.menus.forEach(item=>{
                            if (item.url.indexOf('mdfpwd') < 0) {
                                newMenu.push(item);
                            }
                        });
                        return newMenu;
                    }else{
                        return this.menus;
                    }
                }
            }
        },
    }
</script>
<style lang="less" scoped>
    .user-center {
        .el-col {
            margin: 10px auto;
            border-radius: 4px;
        }
    }

    /deep/.el-tabs__header {
        margin-bottom: 0px;
    }
    .content {
    }
</style>
